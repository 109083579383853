
.search-bar {
  width: 80%;
  max-width: 800px;
  margin: 20px auto;
  display: flex;
  gap: 1rem;
}

.search-bar .search-input {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 2px solid var(--secondary-color);
  flex-grow: 1;
}

.search-bar .search-input:focus {
  outline: none;
  border: 2px solid var(--secondary-color);
  box-shadow: 0 0 5px var(--secondary-color);
}

.search-bar .search-button {
  background-color:var(--secondary-color);
  color: var(--primary-color);
  font-weight: bold;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}
