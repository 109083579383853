body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: white;
  --secondary-color: #c24848;
  --tertiary-color: #812424;
  --main-background-color: rgb(238, 238, 238);
}

#root {
  height: 100vh;
  display: grid;
  grid:
    'header' auto
    'main' 1fr
    'footer' auto
    / 1fr;
}

main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  background-color: var(--main-background-color);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
