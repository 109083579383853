
.main-footer {
  grid-area: footer;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  padding: 1.5rem;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.main-footer span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.main-footer span a {
  color: var(--primary-color);
}


