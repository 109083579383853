.pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}

.pagination .total-pages{
  text-align: center;
}

.pagination .pagination-buttons {
    display: flex;
    gap: 1rem;
}

.pagination .pagination-buttons button {
    background: none;
    border: none;
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;

}


