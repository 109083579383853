
.nav-bar {
  display: flex;
  gap: 1rem;
}

.nav-bar a {
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}