
.home-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  height: 100%;
}
.menu-option {
  color: var(--primary-color);
  background-color: #c24848;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  border-radius: 5px;
  font-size: calc(1.2vw + 1.2vh + .5vmin);
}

.menu-option:hover {
  background-color: var(--tertiary-color);
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .home-menu {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }

  .menu-option {
    width: 100%;
    border-radius: 0;
    font-size: 1rem;
    padding: 3rem;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-top: 1px solid var(--secondary-color);
  }

  .menu-option:last-child {
    border-bottom: 1px solid var(--secondary-color);
  }

  .menu-option:hover {
    background-color: var(--tertiary-color);
    color: var(--primary-color);
    font-weight: bold;
  }
}
