.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

  .loading:before {
    content: '';
    width: 48px;
    height: 48px;
    border: 5px solid var(--secondary-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite, fadein 0.3s linear 0.3s both;
  }

  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }