
.header {
  grid-area: header;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--secondary-color);
}

.header a {
  text-decoration: none;
}

.header a h1 {
  font-size: 3.5rem;
  color: var(--primary-color);
}
